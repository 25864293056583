
@import './colors.scss';

.home-sidebare{
  position: fixed;
  bottom: 0;
  left: 0;
  top: 64px;
  background-color: white;
  border-right: 1px solid $dim;
  z-index: 1;
  right: 75% ;
  padding: 10px;

}
.search-user-wrapper{
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: space-between;
}
.w-50{
  width: 48% ;
}
.center{
  text-align: center;
}
.page-contents{
  margin-top: 75px;
}
.center-contents{
  margin-left: 25%;
  margin-right: 25%;
  margin-top: 75px;
}
.home-toolbar {
  position: sticky;
  top:60px;
  z-index: 2;
}
.home-center-contents{
  margin-left: 30%;
  margin-right: 30%;
  margin-top: 75px;
}
.center-contents-profile-posts{
  width: 60%;
  margin-left: 20%;
  margin-top: 20px;
}
.center-contents-profile{
  // width: 45%;
  margin-left: 15%;
  margin-right: 15%;
  margin-top: 75px;
}
.center-contents-upload{
  // width: 45%;
  margin-left: 42%;
  margin-right: 42%;
  margin-top: 15px;
}
.profile-about-edit{
  position: absolute;
  top: -15px;
  right: -10px;
}
.profile-photo-edit{
  position: absolute;
  top: -15px;
  // right: -10px;
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}
.center-contents-full{
  margin-left: 15%;
  margin-right: 15%;
  margin-top: 75px;
}
.home-sidebare-item{
  padding: 15px 15px 15px 5%;
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin-top: 10px;
  transition: .3s;
}
.top-header{
  // background-color: $info;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  height: 30px;
  align-items: center;
  z-index: 1; 
  padding-left: 3%;
  padding-right: 3%;
  border-bottom: 1px solid $img-bg;
  background: $dim;
}
.header{
  // background-color: $info;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  height: 60px;
  align-items: center;
  z-index: 1; 
  padding-left: 3%;
  padding-right: 3%;
  border-bottom: 1px solid $img-bg;
  background: white;
}

.header-photo{
  width:35px;
  height:35px;
  border-radius:17px;
  background-color: $img-bg;
}

.header-button{
  border-radius: 50% !important;
  border-width: 0 !important ;
  background-color: $img-bg !important ;
  transition: .3s;
}
.header-button:hover{
  color: white !important;
  background-color: $primary !important;
  cursor: pointer !important ;
}

.header-button{
  border-radius: 50% !important;
  border-width: 0 !important ;
}

.header-home-button{
  padding-left: 5px;
  padding-right: 5px;
  height: 40px;
  border-radius: 5px;
  background-color: white;
  transition: .3s;
  width: 250px;
}

.header-home-button:hover{
  background-color: $primary ;
  color: white !important;
}

.home-sidebare-item:hover{
  background-color: $primary !important ;
  color: white !important ;
  cursor: pointer !important;
}

.active-sidebare-item{
  background: $primary;
  color: white;

}
.home-right{
  position: fixed;
  background-color: white;
  z-index: 0;
  top:64px;
  left: 77%;
  right: 0px;
}

.header-right-wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 250px;
}
.header-item-ht{
  height: 63px;
}
.home-hear{
    padding: 20;
}
.post-logo{
    width: 30px;
    height: 30px;
    border-radius: 15px !important;
}

.flex {
    display: flex;
  }
  .flex-row{
    flex-direction: row;
  }

  .flex-column{
    flex-direction: column;
  }

  .items-center{
    align-items: center;
  }
  .justify-between{
    justify-content: space-between;
  }
  .justify-center{
    justify-content: center;
  }
  .justify-end{
        justify-content: flex-end;
    }
  
  .bg{
    background: $dim !important;
  }
  .pointer{
    cursor: pointer !important;
  }
  // TEXT STYLE
  .bold{
      font-weight: bold;
  }
  .black{
      color: black;
  }
  .rigth{
      text-align: right;
  }
  .white{
      color: white;
  }
  // MARGINS
  .mg-l-10{

      margin-left: 10px;
  }
  .mg-l-chatuser{
    margin-left: 5%;
  }
  .mg-l-20{
    margin-left: 20px;
  }
  .mg-l-30{
    margin-left: 30px;
  }
  .mg-l-sidebare{
    margin-left: 5%;
  }
  .mg-0{
    margin: 0px !important;
  }
  .mg-t-20{
      margin-top:20px !important;
  }
  .mg-t-30{
    margin-top:30px !important;
  }
  .mg-t-10{
    margin-top:10px !important;
  }
  .mg-t-50{
    margin-top: 50px;
  }

  .mg-b-20{
    margin-bottom:20px !important;
  }
  .mb-10{
    margin-bottom:10px !important;
  }
.video-player > video{
  object-fit: cover;
}
//   PADDINGS
.pd-15{
  padding: 15px;
}
.pd-20{
    padding: 20px;
}

.ant-card-body{
  padding: 15px !important;
}
// HOME Header

  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }
  
  .dropdown-content a:hover {background-color: #f1f1f1;}
  
  .dropdown:hover .dropdown-content {
    display: block;
  }


  .messages-header{
    position: absolute;
    background: $dim;
    top:0;
    left:0;
    right:0;
    display: flex;
    justify-content: flex-end;
  }
  .chat-unread-tag{
    position: absolute;
    right: 5%;
    top:3%
  }
  .chat-sidebare{
    position: fixed ;
    top:64px;
    left: 78%;
    right: 0;
    bottom: 0;
    padding: 0px 10px;
    z-index: 0;
    border-left: 1px solid $dim;
  }
  .chat-messages-section{
    position: fixed;
    top: 64px;
    bottom: 0px;
    left: 25%;
    right: 22%;
    display: flex;
    flex-direction: column;
    // background-color: lightgreen;
  }
  .chat-users-list{
    display: flex;
    flex-direction: column;
  }
  .chat-user{
    display: flex;
    padding: 7px 5px;
    border-radius: $radious;
    
  }
  .chat-user:hover{
    background-color: $img-bg;
    cursor: pointer;
  }

  .chat-user-active{
    display: flex;
    border-radius: $radious;
    padding: 7px 5px;
    background-color: $img-bg;
    transition: .3s;
    cursor: pointer;
  }
  // .chat-user-active:hover{
    // background-color: $dim;
  // }

  .chat-input{
    position: absolute;
    bottom: 20px;
    left: 10px;
    right: 20px;
  }
  .chat-input-field{
    padding: 5 !important;
    margin-right: 10px !important;
    margin-left: 10px !important;
    // margin-bottom: 23px !important;
  }

  .selectable-form{
    padding-top: 20px;
    background-color: aqua;
  }

  .chat-message{
    // margin-top: 10px !important ;
    margin-bottom: 5px !important;
    width: fit-content;
    padding: 10px;
    border-radius: 5px !important;
  }
  .chat-user-message{
    background-color: $img-bg;
    color: $black;

  }


  .chat-my-message{
    background-color: $primary !important ;
    color: white !important ;
    border: none;
    // float: right;
  }
  .chat-my-message-wrapper{
    display: flex;
    justify-content: flex-end;
  }
  .chat-user-message-wrapper{
    display: flex;
    justify-content: flex-start;
  }
  .send-message-btn{
    height: 44px !important;
  }


  // ADDPOST

  input[type="file"] {
    display: none;
}
.post-photo-select {
    border-radius: 3px;
    border: 1px dotted lightgrey;
    background-color: $dim;
    padding: 6px 12px;
    cursor: pointer;
    height: 105px;
    margin-bottom: 10px;
    width: 105px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center
}
.posts-photos-wrapper{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.posts-photo{
  height: 105px;
  width: 105px;
  object-fit: cover;
  border-radius: 3px;
  margin-bottom: 10px;
}

.profile-page-photo-wrapper{
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.comments-likes-wrapper{
  padding-top: 10px;
  padding-bottom: 10px;
}
.profile-page-photo {
  width:100px;
  height:100px;
  border-radius:50px; 
  top:70px;
  background-color: $primary ;
}


// TEXT



// fins user
[data-theme='compact'] .site-collapse-custom-collapse .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
  background: $dim;
  border-radius: 2px;
  margin-bottom: 24px;
  border: 0px;
  overflow: hidden;
}

.chat-top-section{
  flex-basis: 50px;
  padding: 10px 20px;
  display: flex;
  justify-content: flex-end;
  // flex-basis: 200px;
}
.chat-center-section{
  flex-grow: 1;
  overflow: scroll;
  padding: 20px;

}
.chat-bottom-section{
  flex-basis: 50px;
  padding: 20px 20px;
}


.ant-collapse-borderless > .ant-collapse-item{
  border: none !important;
}
.ant-collapse > .ant-collapse-item{
  border: none !important;

}



// CALLING
.call-wrapper{
  position: fixed;
  z-index: 100;
  background: rgba(28, 43, 54, .9);
  top: 5%;
  right: 10%;
  left: 10%;
  // bottom: 5%;
  border-radius: 10px;
  color: white;
  display: flex;
  flex-direction: column;

}
.call-wrapper-top{
  background: rgba(28, 43, 54, .7);
  border-radius: 10px;
  padding: 10px;
}
.call-wrapper-center{
  flex-grow: 1;
  background: rgba(28, 43, 54, .7);
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}
.call-wrapper-bottom{
  background: rgba(28, 43, 54, .6);
  border-radius: 10px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.floating-video{
  position: absolute;
  right: 0;
  top:53px;
  width:150px;
  border-radius:10px;
  // border:2px solid $primary;
  overflow:hidden
}
.user-video{
  width: inherit;
}
.partner-video{
  width: inherit;
  height: 100%;
}

.partner-video-all{
  position: fixed;
  z-index: 2000;
  left:10px;
  top:0px;
  width: 200px;
  bottom: 600px;
}
.call-button{
  border-radius: 50% !important;
  border-width: 0 !important ;
  height: 100px;
  width:100px;
  background-color: $img-bg !important ;
  transition: .3s;
}
.incomming-call{
  display: flex;
  flex-direction: column;
}

.float-right{
  float: right;
  font-size: 70% !important ;
}
.float-left{
  float: left;
  font-size: 70% !important ;
}
.login-wrapper{

}
/* Portrait and Landscape */
@media only screen 
  // and (min-device-width: 320px) 
  and (max-device-width: 768px) { 
    .home-center-contents{
      margin-left: 0%;
      margin-right: 0%;
    }
    .center-contents{
      margin-left: 0%;
      margin-right: 0%;
    }

    .center-contents-profile-posts{
      width: 0%;
      margin-left: 0%;
      margin-top: 20px;
    }
    .header-home-button{
      display: none;
    }
    .header-right-wrapper{
      display: none ;
    }
    .center-contents-profile{
      margin-left: 3%;
      margin-right: 3%;
    }
    .center-contents-full{
      margin-left: 3%;
      margin-right: 3%;
    }
    .chat-messages-section{
      // position: fixed;
      // top: 64px;
      // bottom: 0px;
      left: 0%;
      // right: 22%;
      // display: flex;
      // flex-direction: column;
    }

    .deactivate-button {
      background-color: aqua;
    }
}

$black: rgba(28, 43, 54, 1);
// $primary: #3366FF;
$primary: #1890ff;
$success: #38C449; //green
$info: #4BA7FC; //snapchat
$warning: #FFEB11;
$red: #FF4235;
$dim: #f0f2f5;
$img-bg: #E4E9F2;
$bg: #F7F9FC;
$msg-dark : #607D8B;
$statusBarHeight: 0;
$radious: 5px;

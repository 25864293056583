@import '../../assets//styles/colors.scss';

.header-navigation{
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
 margin-left: 10%;
 margin-right: 10%;
//  padding: 5px ;
}
.nav-item{
    // padding: 10px 25px;
    display: flex;
    cursor: pointer;
    // width: 100%;
    flex-grow: 1;
    height: 50px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    transition: .3s;
}
.nav-item:hover{
    background-color: $dim;
}
.active-item{
    background: $primary;
    color: white;
}
.active-item:hover{
    background: $primary;
    color: white;
}
@media only screen 
  and (max-device-width: 768px) { 
    .header-navigation{
        margin-left: 0;
        margin-right: 0;
    }
    
}